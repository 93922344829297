.tableratesgenerator {

}

header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  padding:0px 20px;
  font-size: calc(10px + 2vmin);
  color: white;
}

.actions {
  background-color: #667185;
  padding:20px;
  color:white;
}

.main {
  padding:20px;
}

.generator {
  width: 45%;
  float: left;
  height: 100%;
}

.preview {
  width: 50%;
  float: right;
  height: 100%;
}